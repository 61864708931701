import React from 'react'
import {
  IonPage,
  IonContent,
  IonProgressBar,
  IonRow,
  IonGrid,
  IonCol,
  IonHeader,
  IonAlert,
  IonModal,
  IonIcon,
  IonSpinner,
  IonBadge,
} from '@ionic/react'
import { GuruSlider, GuruSlide } from '../../components'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { first } from 'lodash'
import User from '../../models/User'
import Settings from '../../models/Settings'
import cartToolbarIcon from '../../assets/icons/nav_cart.svg'
import bell from '../../assets/icons/nav_notification.svg'
import dispatchToolbarIcon from '../../assets/icons/icono_despacho.svg'
import feedbackNoScopeImage from '../../assets/feedback-no-scope.png'
import guruProImg from './../../assets/turbant_pro.png'
import { close } from 'ionicons/icons'
import { PromotionModel, Promotion } from '../../models/PromotionModel'
import { ProductModel } from '../../models/ProductModel'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import Notifications from '../../models/Notifications'
import { ProviderModel, Provider } from '../../models/ProviderModel'
import SurveyModel from '../../models/Survey'
import Category, { CategoryWithProducts } from '../../models/Category'
import { PromotionModal } from '../promotion-modal'
import ModalsController from '../modals-controller/ModalsController'
import { registerBasicUserInfoEvent } from '../firebase/firebaseTags'


import { Capacitor } from '@capacitor/core'
import { getAppCurrentVersion } from '../../clients/version'
import { appShouldUpdate } from '../../utils/appShouldUpdate'

// import guruDespachoIcon from '../../assets/icons/guru_despacho.svg'
// import guruDespachoIconXl from '../../assets/icons/guru-despacho-xl.svg'
// import exclamationMarkInitial from '../../assets/icons/exclamation-mark-initial.svg'
// import exclamationMarkFinal from '../../assets/icons/exclamation-mark-final.svg'

// HOC for getting into props shouldUpdate

import { StaticContext } from 'react-router-dom'
import { bannerMapping } from '../../utils/banners'
import { EventEmitter } from '../../utils/events'
import { getLocalStorageObject, getLocalUserData } from '../../utils/localstorageData'
import { refreshNotifications } from '../../pages/reusableFunctions/Notifications'

// Update APP
import Form from '../form/Form'
import { validatorStorage, getQueryVariable } from '../reusableFunctions/CleanStorage'
import { getDeepLinkRouteData } from '../deeplinks/deeplinksRoutes'
import { viewVendorEvent } from '../firebase/firebaseTags'
import { Device } from '@capacitor/device'
import { AppLauncher } from '@capacitor/app-launcher'
import { App } from '@capacitor/app'

// Events
import { counterEvent } from '../../clients/eventCounter'
import { find } from '../../clients/order'

// Components
import GlobalComponents from '../../components'
import PromotionSlider from '../../components/promotion-slider'
import ToolBar from '../../components/tool-bar/ToolBar'
import LoadingCover from '../../components/loading-cover/LoadingCover'
import FadeBackground from '../../components/fade-background/FadeBackground'
import ConfirmOrder from '../../pages/confirm-order/ConfirmOrder'
import CouponsCountCard from '../../components/coupons-count-card/CouponsCountCard'
import { ProvidersCardsRenderer } from '../../components/providers-cards-renderer'
import FeedbackNoScope from '../../components/feedback-no-scope/FeedbackNoScope'
import LoadingCoverUserGuest from '../../components/loading-cover/LoadingCoverGuestUser'

// import { categoryTreeOfVendor } from '../../clients/category'
import { ifExistAndMayorToZero } from '../../utils/arrays'
import { countryCl, countryMx, countryPe } from '../../utils/countriesTexts'
import { getOffersOfHome } from '../../clients/product'
import { getCartLength } from '../../utils/cart'
import { HashtagModel } from '../../models/HashtagModel'
import TextVariations from '../../models/TextVariations'
import './Home.scss'
import { isModalPromotionVisualized } from '../../utils/promotionModalFunctions'
import HomeBrandsSlider from '../../components/home-brands-slider/HomeBrandsSlider'
import { navigateToCart } from '../../utils/navigation'
import PerformanceTimer from '../../models/PerformanceTimer'
import BotonGuruDespacho from '../../assets/boton_guru_despacho.png'

interface IPathParams {}

interface RouteState {
  singleProviderId?: string
  nameProvider?: string
  minumun?: any
  banners?: any
  vendorId?: any
  brandId?: any
  categoryId?: any
  subcategoryId?: any
  deeplinkGuruPro?: any
  termsandconditionsUser?: any
  recoveryPassword?: any
  oneVendor?: boolean
  categoryTree?: any
  vendorName?: string
  from?: string
  id?: string
  title?: string
  singleProvider?: string
  isGuestUser?: boolean
  lateRegistration?: boolean
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  notification: Notifications
  surveyModel: SurveyModel
  hashtagModel: HashtagModel
  coupons: any
  userInfoMe: any
  ordersConfirm: any
  userOffices: any
}

interface State {
  promotions: Promotion[]
  loading: any
  loadingPromos: any
  categories: CategoryWithProducts[]
  latestUpdate: Date
  office: any
  offices: any
  appVersion: any
  // nextUpdate: any
  lock: any
  modalGuruPro: boolean
  spinner: boolean
  loadingProviders: boolean
  notOffice: boolean
  userInfo: any
  isLoadingApp: boolean
  providers: any
  vendors: any
  groupBanners: any
  timePerSlide: number | null
  dataVendors: any
  infoVendor: any
  marketingBanners: Promotion[]
  showData: boolean
  promotionsModals: any[]
  isPromotionModalOpen: boolean
  currentPromotion: any
  showModals: boolean
  unreadNotificationsUser: number
  loadingCover: boolean
  oneVendor: boolean
  wentDeeplink: boolean
  page: number
  limit: number
  hasReadTerms: any
  productCart: any
  isUserInvited: boolean
  availableCoupons: number
  providersRibbons: any
  showAlertIsUserInvited: boolean
  offersProducts: any
  cartLengthData: any
  animateAtc: any
  guruDespachoItem: any
  guruDespachoIndex: number
}

class Home extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.initialState
  }

  initialState: State = {
    loading: true,
    loadingPromos: true,
    promotions: [],
    categories: [],
    office: {},
    offices: [],
    latestUpdate: new Date(2000, 1, 1),
    appVersion: null,
    // nextUpdate: null,
    lock: false,
    modalGuruPro: false,
    spinner: true,
    loadingProviders: true,
    userInfo: '',
    notOffice: false,
    isLoadingApp: true,
    providers: {},
    vendors: [],
    groupBanners: [],
    timePerSlide: null,
    dataVendors: {},
    infoVendor: {},
    showData: true,
    promotionsModals: [],
    isPromotionModalOpen: false,
    currentPromotion: {},
    marketingBanners: [],
    showModals: false,
    unreadNotificationsUser: 0,
    loadingCover: true,
    oneVendor: false,
    wentDeeplink: false,
    page: 0,
    limit: 10,
    hasReadTerms: false,
    productCart: 0,
    isUserInvited: false,
    availableCoupons: 0,
    providersRibbons: {},
    showAlertIsUserInvited: false,
    offersProducts: [],
    cartLengthData: { cartLength: 0, prevLength: 0 },
    animateAtc: '',
    guruDespachoItem: undefined,
    guruDespachoIndex: 0
  }

  textVariations = new TextVariations('home', true)
  performanceTimer = PerformanceTimer.getInstance()
  mounted: Boolean = false

  async componentDidMount() {

    /* getAppCurrentVersion info and if should pop up alert message for soft or hard update */
    let appCurrentVersionValue = await getAppCurrentVersion()
    const shouldUpdate = await appShouldUpdate(
      appCurrentVersionValue.versionAndroid,
      appCurrentVersionValue.versionIos
    )

    let storeUrl = null

    if(Capacitor.getPlatform() === 'android') {
      storeUrl = appCurrentVersionValue.androidStoreUrl
    }

    if(Capacitor.getPlatform() === 'ios') {
      storeUrl = appCurrentVersionValue.iosStoreUrl
    }

    appCurrentVersionValue.shouldUpdate = shouldUpdate
    appCurrentVersionValue.url = storeUrl

    this.setState((prevState) => {
      return {
        ...prevState,
        appVersion: appCurrentVersionValue,
      }
    })

    const officeRegisterSuccessful = localStorage.getItem('officeRegisterSuccessful')
    if (!officeRegisterSuccessful) {
      const userHasOffice = await this.checkUserHasOffice(this.props.userOffices)
      if (!userHasOffice) return
    }

    const {
      location: { state: locationState },
    } = this.props
    this.setState({ isUserInvited: locationState?.isGuestUser || false })

    this.updateCartCount()
    this.loadAllHome(null, this.props.userOffices)
    this.registerAccess(12, 'hours')
    registerBasicUserInfoEvent({
      eventName: 'view_home',
      placementName: 'home',
    })
    localStorage.removeItem('fromBrandData')
  }

  checkUserHasOffice = async (userOffices: any) => {
    const countryCode = getLocalStorageObject('countryName')?.countryCode
    const validationCountries = [countryPe, countryMx, countryCl] 

    if (!validationCountries.includes(countryCode)) {
      localStorage.setItem('officeRegisterSuccessful', 'true')
      return true
    }

    const userHasOffice = Boolean(userOffices?.length)
    if (!userHasOffice) {
      this.props?.history?.push('/office-localization', { lateRegistration: true })
    } else {
      localStorage.setItem('officeRegisterSuccessful', 'true')
    }
    return userHasOffice
  }

  updateCartCount = () => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      this.setState({ cartLengthData: { cartLength: updatedCartLength, prevLength: updatedCartLength } })
    }

    this.getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      this.setState((prevState: any) => {
        let newCartLength = prevState.cartLengthData.cartLength
        if (cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if (cartUpdateAction === 'subtract') newCartLength = newCartLength - 1

        const didAdd = newCartLength > prevState.cartLengthData.cartLength
        if (didAdd) {
          this.setState({ animateAtc: 'badge-anim-in' })
          setTimeout(() => {
            this.setState({ animateAtc: 'badge-anim-out' })
          }, 1)
        }
        return {
          cartLengthData: {
            cartLength: newCartLength,
            prevLength: prevState.cartLengthData.cartLength,
          },
        }
      })
    })
  }

  loadAllHome = async (changedOffice?: any, offices?: any) => {
    registerBasicUserInfoEvent({
      eventName: 'click_start_button',
      placementName: 'home'
    })
    this.mounted = true
    const {
      location: { state: locationState },
      user,
      surveyModel,
      history,
    } = this.props
    const firstEntered = Boolean(localStorage.getItem('firstEntered'))

    // const nextUpdate = String(localStorage.getItem('@update'))

    this.validateDeeplinks()
    this.checkTermsAndConditions(this.props.userInfoMe)

    let requests = [user.appCurrentVersion(), this.getProvidersRibbons()]

    const secondaryRequests = [
      this.getPromotionModal(),
      this.getOffers(),
      this.promotionsAndMarketing(),
      this.getAvailableCoupons(),
    ]

    if (firstEntered) requests = requests.concat(secondaryRequests)

    await Promise.all(requests).then(async ([appInfo]) => {
      const newState: any = {}
      const { userInfoMe } = this.props

      newState.timePerSlide = appInfo?.bannerTime || 5000

      let info = await Device.getInfo()
      const appInfoCapacitor = await App.getInfo().catch(() => null)

      const {shouldUpdate, appVersion, requiredUpdate} = this.props


      if (info.platform !== 'web') {
        info = Object.assign(info, appInfoCapacitor, { appBuild: appInfoCapacitor?.build })


        // if (appInfoCapacitor?.version && nextUpdate) {
        //   const response = await this.props.user.appVersion(info)
        //   if (response) {
        //     newState.appVersion = response
        //     newState.nextUpdate = JSON.parse(nextUpdate)
        //   }
        // }
      }

      if (userInfoMe?.eventId) localStorage.setItem('userEventsId', userInfoMe.eventId)

      if (userInfoMe) {
        newState.lock = userInfoMe.lock

        if (userInfoMe._id) {
          const stateNotifications = await refreshNotifications(userInfoMe._id)
          newState.unreadNotificationsUser = stateNotifications
        }
      }

      newState.userInfo = this.props.userInfoMe

      validatorStorage(info, shouldUpdate, appVersion, this.state, appInfoCapacitor?.version || '')

      const currentOffice = localStorage.getItem('currentOffice')
      const validatedOffices = offices || getLocalStorageObject('@offices')

      if (validatedOffices) {
        const office = currentOffice ? JSON.parse(currentOffice) : first(validatedOffices)
        this.props.offices.setOffice(office)
        if (!currentOffice) {
          localStorage.setItem('currentOffice', JSON.stringify(office))
        }

        newState.offices = validatedOffices
        newState.office = changedOffice ? changedOffice : office
      }

      this.setState({ ...newState })
    })

    if (!firstEntered) {
      await Promise.all([
        this.getPromotionModal(),
        this.getOffers(),
        this.promotionsAndMarketing(),
        this.getAvailableCoupons(),
      ])
    }

    if (locationState?.from !== 'fromDeeplink') {
      this.handlerGetHome()
      localStorage.setItem('firstEntered', 'true')
    }

    if (getQueryVariable('pushId')) user.pushUpdate(getQueryVariable('pushId'))
    this.update()
    this.notOffice()
    localStorage.setItem('surveys', JSON.stringify(await surveyModel.getSurveysKeys()))

    this.getCart()
    const dependencies = [
      {
        name: 'getProduct',
        get: this.props.productModel.getProductById,
      },
    ]

    localStorage.setItem('loadComplete', JSON.stringify(true))
    EventEmitter.subscribe('NotificationOpened', async (deeplink: any) => {
      const routeData = await getDeepLinkRouteData(deeplink, dependencies)
      EventEmitter.dispatch('DeeplinkExecuted', true)
      history.push(routeData.route, routeData.params)
      this.validateDeeplinks()
    })

    App.addListener('appUrlOpen', async (data: any) => {
      const routeData = await getDeepLinkRouteData(data.url, dependencies)
      history.push(routeData.route, routeData.params)
      this.validateDeeplinks()
    })
  }

  getPromotionModal = async () => {
    const promotions = await this.props.promotion.get('PromotionModal')
    this.setState({ promotionsModals: promotions || [] }, () => {
      if (promotions?.length) this.showPromotionModal()
    })
  }

  getCart = async () => {
    const cartLength: any = await getCartLength()
    this.setState({ cartLengthData: { cartLength, prevLength: cartLength }, productCart: cartLength })
  }

  checkTermsAndConditions = async (user: any) => {
    try {
      setTimeout(() => this.setState({ hasReadTerms: true }), 7000)
      const termsandconditions = user?.termsandconditions !== undefined ? user?.termsandconditions : true
      this.setState({ hasReadTerms: Boolean(termsandconditions) })

      const userInfoLocal: any = localStorage.getItem('@user') || null
      const userInfo = userInfoLocal ? JSON.parse(userInfoLocal) : null

      if (userInfo) {
        const updatedUser = Object.assign(userInfo, { termsandconditions })
        localStorage.setItem('@user', JSON.stringify(updatedUser))
      }
    } catch (err) {
      this.setState({ hasReadTerms: true })
      console.error(err)
    }
  }

  getAvailableCoupons = async () => {
    const coupons = await this.props.coupons.get(0, 10)
    const couponsUsed = await this.props.coupons.getUsed(this.state.page, this.state.limit)

    if (!coupons || !couponsUsed) return

    const couponsAmount = coupons?.filter((coupon: any) => coupon.couponType !== 'choose-vendor')?.length || 0
    const usedCouponsAmount = couponsUsed?.filter((coupon: any) => coupon.couponType !== 'choose-vendor')?.length || 0
    if (coupons) this.setState({ availableCoupons: couponsAmount + usedCouponsAmount })
  }

  getProvidersRibbons = async () => {
    const { providerModel } = this.props
    const ribbonsArray = await providerModel.getProvidersRibbons()

    if (ribbonsArray?.length) {
      const formattedRibbons: any = {}

      ribbonsArray.forEach(({ _id, ribbon, ribbonByComunes }: any) => {
        formattedRibbons[_id] = { ribbon, ribbonByComunes }
      })

      this.setState({ providersRibbons: formattedRibbons })
    }
  }

  filterByVisibleOrders = async () => {
    if (this.props.ordersConfirm) {
      const { page, limit } = this.state

      const ordersConfirm = this.props.ordersConfirm
      const orders = await find(page, limit)

      let ordersVisibles: any[] = []

      for (let i = 0; i < ordersConfirm.length; i++) {
        for (let j = 0; j < orders.length; j++) {
          if (orders[j].orderNumber === ordersConfirm[i].orderNumber) {
            ordersVisibles.push(orders[j])
          }
        }
      }

      localStorage.setItem('ordersConfirm', JSON.stringify(ordersVisibles.length))
    }
  }

  // TODO: Ver que hacemos con esta validación de deeplinks, es necesario?
  validateDeeplinks = () => { 
    /*
    const { location: { state: locationState } } = this.props
    const vendorsToDeepLink = localStorage.getItem('@providers') 
    let parseVendors = vendorsToDeepLink ? JSON.parse(vendorsToDeepLink) : {}
    parseVendors = Object.values(parseVendors)
    const vendorId = locationState?.vendorId || null
    const categoryId = locationState?.categoryId || null
    const subcategoryId = locationState?.subcategoryId || null
    const brandId = locationState?.brandId || null

    console.log("validateDeeplinks: ", {locationState, vendorsToDeepLink, parseVendors, vendorId, categoryId, subcategoryId, brandId})
    if (locationState?.from === 'fromDeeplink' && vendorId) {
      console.log("if-1")
      if (!categoryId && !brandId) {
        console.log("if-2")
        this.deepLinkGoToHomeProvider(vendorId, parseVendors, null)
      }
      if (!categoryId && brandId) {
        console.log("if-3")
        this.deeplinkGoToBrandProvider(parseVendors, vendorId, brandId)
      }
      if (categoryId && subcategoryId && !brandId) {
        console.log("if-4")
        this.deeplinkGoToSubcategoryProvider(vendorId, categoryId, subcategoryId)
        return
      }
      if (categoryId && !brandId){
        console.log("if-5")
        this.deeplinkGoToCategoryProvider(vendorId, categoryId)
      } 
    }
    */
  }

  registerAccess = async (timePassed: number, measure: string) => {
    try {
      const lastAccess = localStorage.getItem('lastAccess')
      const currentDate = new Date()
      const isoCurrentDate = currentDate.toISOString()

      const user = localStorage.getItem('@user')
      const userId = user ? JSON.parse(user) : null

      const isValid = String(lastAccess) !== 'Invalid Date'

      if (lastAccess && isValid) {
        const lastAccessDate = new Date(lastAccess)

        const millisecondsDifference = currentDate.getTime() - lastAccessDate.getTime()

        if (this.calculateTimeDifference(millisecondsDifference, measure, timePassed)) {
          localStorage.setItem('lastAccess', isoCurrentDate)
          counterEvent('home', 'HomeNavigation', userId.id, 'no-banner', 'navigation')
        }
      } else {
        localStorage.setItem('lastAccess', isoCurrentDate)
        counterEvent('home', 'HomeNavigation', userId.id, 'no-banner', 'navigation')
      }
    } catch (err) {
      console.error(err)
    }
  }

  calculateTimeDifference = (millisecondsDifference: number, measure: string, timePassed: number) => {
    const objTimes = [
      {
        measure: ['days', 'day'],
        difference: millisecondsDifference / 1000 / 60 / 60 / 24,
      },
      {
        measure: ['hours', 'hour'],
        difference: millisecondsDifference / 1000 / 60 / 60,
      },
      {
        measure: ['minutes', 'minute'],
        difference: millisecondsDifference / 1000 / 60,
      },
    ]

    let matchDifferenceTime = objTimes.find((timeDiff) => timeDiff.measure.includes(measure))

    if (!matchDifferenceTime) matchDifferenceTime = { measure: [], difference: 0 }

    return matchDifferenceTime.difference > timePassed
  }

  componentWillUnmount() {
    this.mounted = false
    EventEmitter.unsubscribe('UpdateCartCount')
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  private renderToolbar = (singleProvider?: Provider) => {
    const { isLoadingApp, unreadNotificationsUser, productCart } = this.state
    const { history, cartModel } = this.props

    const notificationsBadge = unreadNotificationsUser > 0 ? ' ' : 0

    const userData = getLocalUserData()
    let userName = userData?.name || userData?.role?.name
    const officesLocal = localStorage.getItem('@offices')
    const officesParsed = officesLocal ? JSON.parse(officesLocal) : []
    const officeLocal = localStorage.getItem('currentOffice')
    const officeParsed = officeLocal ? JSON.parse(officeLocal) : null

    return singleProvider ? (
      <ToolBar
        title={singleProvider.alias}
        primaryButtons={[
          {
            icon: cartToolbarIcon,
            key: 'cart',
            onClick: this.goToCart,
            badge: productCart,
            notOffice: isLoadingApp,
          },
          {
            icon: dispatchToolbarIcon,
            key: 'orders',
            onClick: this.goToOrders,
            notOffice: isLoadingApp,
          },
        ]}
        office={officeParsed}
        offices={officesParsed}
        loadOnChangeOffice={this.loadHomeByComuneId}
        secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
        officeModel={this.props.offices}
        cartModel={cartModel}
      />
    ) : (
      <div>
        <p className="paragraph-3 categories-toolbar-text">Categorías</p>
        <ToolBar
          title={userName}
          office={officeParsed}
          offices={officesParsed}
          loadOnChangeOffice={this.loadHomeByComuneId}
          primaryButtons={[
            {
              icon: bell,
              key: 'notifications',
              onClick: this.goToNotifications,
              notOffice: isLoadingApp,
              badge: notificationsBadge,
            },
            {
              icon: cartToolbarIcon,
              key: 'cart',
              onClick: this.goToCart,
              badge: productCart,
              notOffice: isLoadingApp,
            },
          ]}
          secondaryButtons={[
            {
              key: 'category',
              onClick: this.goToCategory,
              notOffice: isLoadingApp,
            },
          ]}
          renderProducts={() => this.renderProducts()}
          officeModel={this.props.offices}
          cartModel={cartModel}
        />
      </div>
    )
  }

  private renderSearchBar = (singleProvider?: Provider) =>
    !singleProvider ? (
      <div className="home-search-wrapper">
        <GlobalComponents.SearchBar {...this.props} />
      </div>
    ) : (
      ''
    )

  private getProviderIdByDeeplink = (promo: Promotion) => {
    const deeplinkSplitted = promo?.deeplink?.split("/")
    if(!deeplinkSplitted) {
      return undefined
    }

    const lastIndex = deeplinkSplitted.length - 1
    const providerId = deeplinkSplitted[lastIndex]
    return providerId
  }

  private renderHomeSlider = (singleProvider?: Provider, bannersType?: string | undefined, key?: any) => {
    const { cartModel, productModel, history, providerModel } = this.props
    let providers: any = providerModel.state.providers
    const { loadingPromos, timePerSlide } = this.state

    let bannersSegmented: Promotion[] = []
    bannersSegmented = key

    const loadingLabel = bannersType === 'marketingBanners' ? 'publicidad' : 'promociones'
    const mainSliderContent = ['promotions', 'hashtags', 'marketingBanners'].includes(bannersType)
      ? this.state[bannersType as keyof State]?.sort((p1: any, p2: any) => p1.weight - p2.weight)
      : bannersSegmented.sort((p1, p2) => p1.weight - p2.weight)
    let bigPromotions: Promotion[] = mainSliderContent.filter(({ weight }: any) => weight < 100)
    bigPromotions = bigPromotions.map((promo: Promotion) => {
      return {
        ...promo,
        providerId: this.getProviderIdByDeeplink(promo)
      }
    })

    const getPlacementByPromotionType = (bannerType: string) => {
      if(bannerType === 'marketingBanners') {
        return 'home_marketing'
      } else  {
        return 'home_promo'
      }
    }

    const getPlaceholderNameByBannersType = (bannerType: string) => {
      return bannersType === 'Promotion' ? 'promo' : 'marketing'
    }

    const smallPromotionsLeft = mainSliderContent.filter(({ weight }: any) => weight >= 100 && weight < 200)
    const smallPromotionsRight = mainSliderContent.filter(({ weight }: any) => weight >= 200)
    const mapping = singleProvider != null ? bannerMapping[singleProvider.alias] : undefined
    if (bigPromotions.length === 0 && mapping) {
      bigPromotions = [
        {
          _id: 'none',
          image: {
            url: 'mapping', // Asumiendo que 'mapping' es una string que representa una URL
            width: 100,
            height: 200,
            local: 'banners',
          },
          name: 'mapping',
          price: 1,
          providerId: 'none',
          sku: 'none',
          weight: 50,
          // Propiedades opcionales
          coupons: false,
          provider: false,
          html: false,
          codeHtml: '',
          publicity: false,
          type: '',
          deeplink: '',
          vendor: undefined,
          tag: undefined,
          providerName: undefined,
          product: null,
        },
      ]
    }
    return loadingPromos ? (
      <div className="loading-section">
        <p className="loading-promotions">Cargando {loadingLabel}</p>
        <IonSpinner className="spinner-mid" name="crescent" {...({} as any)}/>
      </div>
    ) : (
      (bigPromotions.length > 0 || smallPromotionsLeft.length > 0 || smallPromotionsRight.length > 0) && (
        <div className="promotions-section">
          <IonGrid {...({} as any)}>
            {bigPromotions.length > 0 && (
              <IonRow className="one-promo" {...({} as any)}>
                <IonCol align-self-center {...({} as any)}>
                  <div className="promotions-home-wrapper">
                    <PromotionSlider
                      promotions={bigPromotions}
                      cartModel={cartModel}
                      productModel={productModel}
                      history={history}
                      providers={providers}
                      timePerSlide={timePerSlide}
                      typeBanners={bannersType ?? ''}
                      validateDeeplinks={this.validateDeeplinks}
                      placement={getPlacementByPromotionType(bannersType) ?? 'home'}
                    />
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>

          {/** marketing | promo placeholder */}
          <div 
            id={`home-ads-${getPlaceholderNameByBannersType(bannersType)}-placeholder`} 
            className={`home-ads-${getPlaceholderNameByBannersType(bannersType)}-placeholder`}
          >  
          </div>
        </div>
      )
    )
  }

  private renderProducts = () => {
    const { categories, loading } = this.state

    return loading ? (
      <IonProgressBar type="indeterminate" {...({} as any)}/>
    ) : (
      categories.map(
        (category: any, index: number) =>
          category.products &&
          category.products.length && (
            <GlobalComponents.ProductSlider
              key={category._id}
              products={category.products}
              info={{
                title: category.name,
                id: category._id,
                brandId: '',
              }}
              onMoreInfoClick={this.onMoreInfoClick}
              onGoToCart={this.goToCart}
              {...this.props}
              colorText={category.colorText}
              colorBackground={category.colorBackground}
              categoryPosition={index + 1}
              fromSlider={false}
              placementName={`home-category-product-slider-${category.name}`}
            />
          )
      )
    )
  }

  modalGurupro = () => {
    const { modalGuruPro } = this.state

    return (
      <IonModal className="guru-pro-modal" backdropDismiss={false} isOpen={modalGuruPro} {...({} as any)}>
        <div className="content">
          <div className="header">
            <IonIcon icon={close} onClick={this.closeModalGuruPro} {...({} as any)}/>
          </div>
          <div className="body-guru-pro">
            <div className="star" style={{ backgroundImage: `url(${guruProImg})` }}></div>
          </div>
          <div className="container-info-productModal">
            <div className="container-title-modal">Gurú Pro</div>
            <div className="info-modal">
              Te invitamos a ser un Gurú Pro completando tu información en el perfil. Así accederás a promociones
              especiales para ti e información sobre productos destacados y más vendidos.
            </div>
          </div>
          <div className="footer">
            <div className="to-perfil" onClick={this.goProfile}>
              Ir a perfil
            </div>
          </div>
        </div>
      </IonModal>
    )
  }

  modalNotOfifice = () => {
    const { notOffice } = this.state

    return (
      <IonModal className="not-office-modal" backdropDismiss={false} isOpen={notOffice} {...({} as any)}>
        <div className="content">
          <div className="header">
            <IonIcon icon={close} onClick={this.signOut} {...({} as any)}/>
          </div>
          <div className="container-info-productModal">
            <div className="container-title-modal">No posees dirección</div>
            <div className="info-modal">Sin la dirección de tu negocio no podrás realizar compras.</div>
            <div className="text-modal">Por favor, comunícate con nosotros para añadir la información faltante.</div>
          </div>
          <div className="footer">
            <div className="to-perfil" onClick={this.signOut}>
              ok
            </div>
          </div>
        </div>
      </IonModal>
    )
  }

  closeModalGuruPro = async () => {
    this.setState({
      modalGuruPro: false,
    })
    registerBasicUserInfoEvent({
      eventName: 'quit_guru_pro',
      placementName: 'home'
    })
    const data = {
      showModalGuruPro: false,
    }
    await this.props.user.updateUserValues(data)
  }

  goProfile = async () => {
    const data = {
      showModalGuruPro: false,
    }
    registerBasicUserInfoEvent({
      eventName: 'go_to_profile',
      placementName: 'home'
    })
    await this.props.user.updateUserValues(data)
    this.props?.history?.push('/profile')
  }

  handlerGetHome = async () => {
    this.setState({ isLoadingApp: true, loadingProviders: true, loading: true })
    this.getHomeData()
    this.getBannersPromotion()
  }

  updateHome = async (lastAccessLocalStorage: string, namelocalStorage: string, type: string) => {
    const lastAccess = localStorage.getItem(lastAccessLocalStorage)
    const currentDate = new Date()
    const isValid = String(lastAccess) !== 'Invalid Date'

    if (lastAccess && isValid) {
      const lastAccessDate = new Date(lastAccess)
      const millisecondsDifference = currentDate.getTime() - lastAccessDate.getTime()
      if (this.calculateTimeDifference(millisecondsDifference, 'hour', 1)) {
        type === 'provider' && this.getHomeData()
        type === 'banners' && this.getBannersPromotion()
      } else {
        const homeData = localStorage.getItem(namelocalStorage)

        const dataHome = homeData && homeData !== 'undefined' ? JSON.parse(homeData) : null
        type === 'provider' && this.setHomeVendors(dataHome)
        type === 'banners' && this.setState({ promotions: dataHome })
      }
    } else {
      type === 'provider' && this.getHomeData()
    }
  }

  getHomeData = async () => {
    const { providerModel } = this.props

    const homeData: any = await providerModel.getHome()
    homeData && this.setHomeVendors(homeData)
    homeData && localStorage.setItem('homeData', JSON.stringify(homeData))
    this.saveLocalStorageDate('lastUpdateHome')
  }

  saveLocalStorageDate = (name: string) => {
    const currentDate = new Date()
    const isoCurrentDate = currentDate.toISOString()
    localStorage.setItem(name, isoCurrentDate)
  }

  setHomeVendors = async (homeData: any) => {
    let segmentVendors = []
    const steps = window.screen.width <= 480 ? parseInt(homeData.steps.steps) * 3 : parseInt(homeData.steps.steps) * 5
    const dataVendors = Object.values(homeData.vendors)
    const oneVendor = dataVendors.length === 1
    if (oneVendor) {
      const item: any = dataVendors[0]
      this.goToHomeProvider(item, null, null, true)
      this.setState({
        dataVendors: dataVendors,
        infoVendor: item,
        loadingProviders: false,
        showData: false,
        showModals: false,
        oneVendor,
        loadingCover: false,
      })
      return
    } else {
      const promotions = await this.props.promotion.get('PromotionModal')

      if (promotions?.length) this.setState({ promotionsModals: promotions }, () => this.showPromotionModal())
      const itemIndex = dataVendors.findIndex((vendor: any) => vendor.name.trim() === "Gurú Despacho")
      if (itemIndex !== -1) {
        const removed = dataVendors.splice(itemIndex, itemIndex + 1)
        dataVendors.push(removed[0])
      }
    }

    while (dataVendors.length) {
      segmentVendors.push(dataVendors.splice(0, steps))
    }

    const groupBanners = homeData.banners.reduce((accumulator: any, currentValue: any) => {
      if (accumulator[currentValue.group]) {
        accumulator[currentValue.group].push(currentValue)
      } else {
        accumulator[currentValue.group] = [currentValue]
      }
      return accumulator
    }, {})

    this.setState({
      vendors: segmentVendors,
      groupBanners: groupBanners,
      isLoadingApp: false,
      loadingProviders: false,
      loadingPromos: false,
      loading: false,
      showModals: true,
      oneVendor,
      loadingCover: false,
    })

    if (!oneVendor) this.performanceTimer.endPerformanceTimer('home')
  }

  update() {
    const currentOffice = localStorage.getItem('currentOffice')
    setTimeout(() => {
      const office = currentOffice && currentOffice !== 'undefined' ? JSON.parse(currentOffice) : null
      if (office) {
        this.mounted &&
          this.setState({
            office,
          })
      }
    }, 3000)
  }

  notOffice() {
    const currentOffice = localStorage.getItem('currentOffice')
    const office = currentOffice && currentOffice !== 'undefined' ? JSON.parse(currentOffice) : null
    this.setState({
      office,
    })

    if (!currentOffice || currentOffice === 'undefined') {
      this.setState({ notOffice: true, modalGuruPro: false, isLoadingApp: true })
    } else {
      this.setState({ notOffice: false, isLoadingApp: false })
    }
  }

  signOut = async () => {
    const { cartModel, user } = this.props
    await cartModel.clearCart()
    await user.signOut()
    this.props?.history?.push('/login')
    window.location.reload()
    this.setState({ notOffice: false })
  }

  async promotionsAndMarketing() {
    const singleProviderId = this.props.location.state ? this.props.location.state.singleProviderId : undefined
    this.getUpdateBannersPromotion()

    const marketingBanners = (await this.props.promotion.get("Marketing"))
      .filter(p => !singleProviderId || p.providerId === singleProviderId)
      .filter(p => p.image != null)

    this.mounted &&
      this.setState({
        loadingPromos: false,
        marketingBanners,
        spinner: false,
      })
  }

  getBannersPromotion = async () => {
    const singleProviderId = this.props.location.state ? this.props.location.state.singleProviderId : null
   
    const promotions = (await this.props.promotion.get("Promotion"))
      .filter(p => !singleProviderId || p.providerId === singleProviderId)
      .filter(p => p.image != null)
    this.mounted && this.setState({ promotions })
    localStorage.setItem('bannerPromotion', JSON.stringify(promotions))
  }

  getUpdateBannersPromotion = async () => {
    const banners = localStorage.getItem('bannerPromotion')

    if (!banners) {
      this.getBannersPromotion()
    } else {
      this.updateHome('lastUpdateHome', 'bannerPromotion', 'banners')
    }
  }

  async componentDidUpdate() {
    if (!this.state.appVersion) {
      const appCurrentVersionValue = await getAppCurrentVersion();
      this.setState({ appVersion: appCurrentVersionValue });
    }
    
    const now = new Date()
    if (now.getTime() - this.state.latestUpdate.getTime() > 60000) {
      this.setState({ latestUpdate: new Date() })
    }
  }

  goStore = () => {
    const { appVersion } = this.state
    if (appVersion && appVersion?.update && appVersion?.url) {
      AppLauncher.openUrl({ url: appVersion?.url })
    }
    this.mounted = false
  }

  skipUpdate = () => {
    var myDate = new Date()
    myDate.setHours(myDate.getHours() + 24)
    // localStorage.setItem('@update', JSON.stringify({ nextUpdate: myDate.getTime() }))
    // this.setState({ nextUpdate: myDate })
    this.mounted = false
  }

  private onMoreInfoClick = (info: { id: string }) => {
    const singleProviderId = this.props.location.state ? this.props.location.state.singleProviderId : undefined
    this.props?.history?.push(`/plp/${info.id}`, { ...info, singleProviderId })
    this.mounted = false
  }

  private goToCart = () => {
    const { notOffice } = this.state

    if (!notOffice) {
      navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state)
    }
    this.mounted = false
    registerBasicUserInfoEvent({
      eventName: 'cart_home_toolbar_icon_click',
      placementName: 'home'
    })
  }

  private goToCategory = () => {
    const { notOffice } = this.state
    registerBasicUserInfoEvent({
      eventName: 'view_categories',
      placementName: 'home'
    })
    if (!notOffice) {
      this.props?.history?.push('/categories')
    }
  }

  private goToOrders = () => {
    this.props?.history?.push('/orders')
    this.mounted = false
    registerBasicUserInfoEvent({
      eventName: 'home_order',
      placementName: 'home'
    })
  }

  private goToNotifications = () => {
    const { notOffice } = this.state
    registerBasicUserInfoEvent({
      eventName: 'notification_home_toolbar_icon_click',
      placementName: 'home',
    })
    if (!notOffice) this.props?.history?.push('/notifications')
  }

  userLock = () => {
    const { cartModel, user } = this.props
    return <Form user={user} cartModel={cartModel} />
  }

  provider = () => {
    return (
      <div className="container-distributo">
        <h1 className="providers-section-title">Proveedores</h1>
      </div>
    )
  }

  goToHomeProvider = (item: any, categoryId?: any, vendorId?: any, oneVendor?: boolean, vendorPosition?: any) => {
    viewVendorEvent('home', item.id, item.alias, vendorPosition)
    this.props?.history?.push(`/home-providers/${item.id}`, {
      singleProviderId: item.id,
      nameProvider: item.alias,
      minumun: item.minimun,
      banners: item.banners,
      categoryId,
      vendorId,
      oneVendor: oneVendor,
      recoveryPassword: this.props.history.location.state,
    })
  }

  providerHome = () => {
    const { loadingProviders, vendors, groupBanners, providersRibbons } = this.state

    if (vendors.length > 0) {
      for (let i = 0; i < vendors.length; i++) {
        const result = vendors[i].find((vendor: any) => vendor.name.trim() === 'Gurú Despacho')

        if (result) {
          this.setState({ guruDespachoItem: result, guruDespachoIndex: vendors[i].indexOf(result) })
          break
        }
      }
    }

    return loadingProviders ? (
      <div className="loading-section-providers">
        <p className="loading-providers">Cargando proveedores</p>
        <IonSpinner className="spinner-mid" name="crescent" {...({} as any)} />
      </div>
    ) : (
      <div className="container-provider-home">
        <div className="heder-provider-home"></div>
        {vendors.map((vendorsGroup: any, index: any) => {
          const values = Object.values(groupBanners)
          return (
            <div key={index}>
              <ProvidersCardsRenderer
                providers={vendorsGroup}
                onProviderClick={this.goToHomeProvider}
                source="home"
                providersRibbons={providersRibbons}
                placementName='home'
              />
              {index < values.length /*&& vendorsGroup.length === 12 */ && (
                <div className="banner-descanso-wrapper">
                  <div>{this.renderHomeSlider(undefined, 'bannersProviders', values[index])}</div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  showPromotionModal = () => {
    const { promotionsModals } = this.state
    const invasiveModalAlreadyVisited = isModalPromotionVisualized(promotionsModals[0]._id)
    if (!invasiveModalAlreadyVisited) {
      this.setState({
        currentPromotion: promotionsModals[0],
        isPromotionModalOpen: true,
      })
    }
  }

  closePromotionModal = async (promotionModal: any) => {
    const { userInfo } = this.state

    await counterEvent('banner-full-screen', promotionModal.type, userInfo?._id, promotionModal._id, 'click')
    this.setState({ isPromotionModalOpen: false })
  }

  renderFadeBackground = (loadingCover: any, hasReadTerms: boolean) => {
    if (!loadingCover && !hasReadTerms) {
      return <FadeBackground visible={true} />
    }
    return ''
  }

  loadingCovers = () => {
    const { isUserInvited, loadingCover } = this.state
    const loadingPhrase = ['Cargando proveedores', 'Cargando productos', 'Cargando categorías']

    const renderLoadingCover = isUserInvited ? (
      <LoadingCoverUserGuest isLoading={loadingCover} title={'¡Bienvenido a Almacén Gurú!'} phrase={loadingPhrase} />
    ) : (
      <LoadingCover isLoading={loadingCover} />
    )

    return <IonPage className="home-page-app ds-content-page" {...({} as any)}>{renderLoadingCover}</IonPage>
  }

  renderCouponsCard = (availableCoupons: any) => {
    return (
      Boolean(availableCoupons) && <CouponsCountCard couponsQuantity={availableCoupons} history={this.props.history} />
    )
  }

  getOffers = async () => {
    const offersProducts = await getOffersOfHome(1)
    this.setState({
      offersProducts: offersProducts?.data?.docs || [],
    })
  }

  showOffers = () => {
    const { offersProducts } = this.state 
    const offersTitle = this.textVariations.getVariationValue('offers.title') || 'Ofertas sólo para ti'

    return (
      <div className="products-slider">
        {ifExistAndMayorToZero(offersProducts) && (
          <GlobalComponents.ProductSlider
            key={`OffersHomeSlider`}
            products={offersProducts}
            info={{
              title: offersTitle,
              id: `OffersHomeSlider`,
              brandId: '',
              hide_more: false,
              vendorName: offersTitle,
            }}
            onGoToCart={this.goToCart}
            fromSlider={false}
            offersHome={true}
            onMoreInfoClick={() => this.props?.history?.push('/vendors-products-offers')}
            placementName={`home-promo-product-carousel`}
            {...this.props}
          />
        )}
      </div>
    )
  }

  renderNoReachInCommune = () => (
    <FeedbackNoScope
      image={feedbackNoScopeImage}
      text="Lamentamos informar que actualmente no contamos con servicios en tu zona."
      callToAction="¡Pronto volveremos con novedades para ti y tu negocio!"
    />
  )

  loadHomeByComuneId = async (changedOffice: any) =>
    this.setState(this.initialState, () => this.loadAllHome(changedOffice))

  getVendor = async (vendorId: string, vendors: any) => vendors.find((vendor: any) => vendor.id === vendorId)

  render() {
    const {
      appVersion,
      lock,
      modalGuruPro,
      notOffice,
      showData,
      showModals,
      loadingCover,
      isPromotionModalOpen,
      currentPromotion,
      oneVendor,
      hasReadTerms,
      availableCoupons,
      cartLengthData,
      animateAtc,
      offersProducts,
      vendors,
    } = this.state
    const {
      location: { state: locationState },
      providerModel,
    } = this.props
    const singleProviderId = locationState ? locationState.singleProviderId : undefined
    const singleProvider = singleProviderId ? providerModel.getById(singleProviderId) : undefined

    if (loadingCover) return this.loadingCovers()
    return (
      <IonPage className="home-page-app ds-content-page" {...({} as any)}>
        {this.renderFadeBackground(loadingCover, hasReadTerms)}
        <IonHeader {...({} as any)}>
          <div className="relative-header-wrapper">
            {cartLengthData.cartLength > 0 && (
              <IonBadge className={`cart-badge-btn ${animateAtc}`} {...({} as any)}>{cartLengthData.cartLength}</IonBadge>
            )}
            {showData && this.renderToolbar(singleProvider)}
          </div>
        </IonHeader>
        {!vendors.length ? (
          this.renderNoReachInCommune()
        ) : (
          <>
            <IonContent {...({} as any)}>
              <ConfirmOrder
                route={'home'}
                shouldRender={!loadingCover && !oneVendor && hasReadTerms}
                {...this.props}
                ordersConfirm={this.props.ordersConfirm}
              />
              {appVersion.s && appVersion.url && appVersion.shouldUpdate && (
                <GuruSlider pager={false} slidesPerView={1}>
                  <GuruSlide>
                    <IonAlert
                      isOpen={appVersion?.update}
                      onDidDismiss={(): void => {}}
                      backdropDismiss={appVersion.required ? false : true}
                      header={appVersion.header}
                      message={appVersion.required ? appVersion.messageRequired : appVersion.message}
                      buttons={
                        appVersion.required
                          ? [
                              {
                                text: appVersion?.buttonUpdate,
                                handler: this.goStore,
                              },
                            ]
                          : [
                              {
                                text: appVersion?.buttonUpdate,
                                handler: this.goStore,
                              },
                              {
                                text: appVersion?.buttonSkip,
                                handler: this.skipUpdate,
                              },
                            ]
                      }
                    
                    />
                  </GuruSlide>
                </GuruSlider>
              )}
              {notOffice && this.modalNotOfifice()}
              <ModalsController
                recoveryPassword={this.props.history.location.state}
                showModals={showModals}
                userInfo={this.props.userInfoMe}
                {...this.props}
              />
              {isPromotionModalOpen && (
                <PromotionModal
                  isOpenPromotion={isPromotionModalOpen}
                  promotionModal={currentPromotion}
                  closePromotionModal={this.closePromotionModal}
                  validateDeeplinks={this.validateDeeplinks}
                  placementName='home'
                  {...this.props}
                />
              )}

              {this.renderSearchBar(singleProvider)}
              {modalGuruPro && this.modalGurupro()}
              <div className="banners-grid-container">
                <IonGrid {...({} as any)}>
                  <IonRow {...({} as any)}>
                    <IonCol col-auto {...({} as any)}> {showData && this.renderHomeSlider(singleProvider, 'marketingBanners')}</IonCol>
                  </IonRow>
                  {this.renderCouponsCard(availableCoupons)}
                  {offersProducts.length > 5 && this.showOffers()}
                  <IonRow {...({} as any)}>
                    <IonCol col-auto {...({} as any)}> {showData && this.renderHomeSlider(singleProvider, 'promotions')}</IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              <HomeBrandsSlider />

              {/* <div className="container-guru-despacho">
                <h3>Gurú Despacho 24 a 48 horas</h3>
                <div>
                  <Link to="/home-fulfillment">Gurú Despacho</Link>
                </div>
              </div> */}

              {this.provider()}
              <div className="banners-grid-container">
                <IonGrid {...({} as any)}>
                  <IonRow style={{overflow: 'hidden'}} {...({} as any)}>
                    <IonCol col-auto {...({} as any)}>{this.providerHome()}</IonCol>
                  </IonRow>
                </IonGrid>
                <br />
              </div>
              {lock && this.userLock}
            </IonContent>
          </>
        )}
      </IonPage>
    )
  }
}

export default withRouter(Home)
