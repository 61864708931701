export interface PromotionModalInterface {
    _id: string;
    type: string;
    active: boolean;
    bussinesTypes: string[];
    group: any;
    isDeleted: boolean;
    deletedAt: null | string;
    name: string;
    image: {
      url: string;
      width: number;
      height: number;
    };
    price: number;
    description: string;
    startDate: string;
    expirationDate: string;
    sku: string;
    countryId: string;
    deeplink: string;
    weight: number;
    provider: any;
    providerId?: any;
    coupons: any;
    publicity: any;
    html: any;
    marketing: any;
    codeHtml: any;
    usernames: string;
    whitelistUsers: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  }
  
  export class PromotionModalClass implements PromotionModalInterface {
    _id: string;
    type: string;
    active: boolean;
    bussinesTypes: string[];
    group: any;
    isDeleted: boolean;
    deletedAt: null | string;
    name: string;
    image: {
      url: string;
      width: number;
      height: number;
    };
    price: number;
    description: string;
    startDate: string;
    expirationDate: string;
    sku: string;
    countryId: string;
    deeplink: string;
    weight: number;
    provider: any;
    providerId?: any;
    coupons: any;
    publicity: any;
    html: any;
    marketing: any;
    codeHtml: any;
    usernames: string;
    whitelistUsers: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  
    constructor(data: PromotionModalInterface) {
      Object.assign(this, data);
    }
  
    static fromJson(json: any): PromotionModalClass {
      // Verificamos que el json tenga la estructura esperada
      if (typeof json !== 'object' || json === null) {
        const error = new Error('Invalid JSON: expected an object');
        console.log({error})
      }
  
      // Verificamos y convertimos los campos
      const promotionModal: PromotionModalInterface = {
        _id: this.ensureString(json._id, '_id'),
        type: this.ensureString(json.type, 'type'),
        active: this.ensureBoolean(json.active, 'active'),
        bussinesTypes: this.ensureStringArray(json.bussinesTypes, 'bussinesTypes'),
        group: json.group,
        isDeleted: this.ensureBoolean(json.isDeleted, 'isDeleted'),
        deletedAt: json.deletedAt === null ? null : this.ensureString(json.deletedAt, 'deletedAt'),
        name: this.ensureString(json.name, 'name'),
        image: {
          url: this.ensureString(json.image?.url, 'image.url'),
          width: this.ensureNumber(json.image?.width, 'image.width'),
          height: this.ensureNumber(json.image?.height, 'image.height'),
        },
        price: this.ensureNumber(json.price, 'price'),
        description: this.ensureString(json.description, 'description'),
        startDate: this.ensureString(json.startDate, 'startDate'),
        expirationDate: this.ensureString(json.expirationDate, 'expirationDate'),
        sku: this.ensureString(json.sku, 'sku'),
        countryId: this.ensureString(json.countryId, 'countryId'),
        deeplink: this.ensureString(json.deeplink, 'deeplink'),
        weight: json.weight,
        provider: json.provider,
        providerId: json.providerId,
        coupons: json.coupons,
        publicity: json.publicity,
        html: json.html,
        marketing: json.marketing,
        codeHtml: json.codeHtml,
        usernames: this.ensureString(json.usernames, 'usernames'),
        whitelistUsers: Array.isArray(json.whitelistUsers) ? json.whitelistUsers : [],
        createdAt: this.ensureString(json.createdAt, 'createdAt'),
        updatedAt: this.ensureString(json.updatedAt, 'updatedAt'),
        __v: this.ensureNumber(json.__v, '__v'),
      };
  
      return new PromotionModalClass(promotionModal);
    }
  
    private static ensureString(value: any, fieldName: string): string {
      if (typeof value !== 'string') {
        const error = new Error(`Invalid ${fieldName}: expected a string`);
        console.log({error})
      }
      return value;
    }
  
    private static ensureNumber(value: any, fieldName: string): number {
      if (typeof value !== 'number') {
        const error = new Error(`Invalid ${fieldName}: expected a number`);
        console.log({error})
      }
      return value;
    }
  
    private static ensureBoolean(value: any, fieldName: string): boolean {
      if (typeof value !== 'boolean') {
        const error = new Error(`Invalid ${fieldName}: expected a boolean`);
        console.log({error})
      }
      return value;
    }
  
    private static ensureStringArray(value: any, fieldName: string): string[] {
      if (!Array.isArray(value) || !value.every(item => typeof item === 'string')) {
        const error = new Error(`Invalid ${fieldName}: expected an array of strings`);
        console.log({error})
      }
      return value;
    }
  }